import { request } from ".";

export const billingApi = {
  me: () => request("/billings/me"),
  getDetails: () => request("/billings/payments-details"),
  getHistory: () => request("/billings/history"),
  payNow: () => request("/billings/pay-now", {}),
  addCard: () => request("/billings/create", {}),
  retrieve: (session_id: string) => request("/billings/retrieve", { session_id }),
  cancel: () => request("/billings/cancel", {})
}