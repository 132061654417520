import dayjs from "dayjs";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useDataStore } from "./data";

export const useUiStore = defineStore('ui', () => {

  const period = ref<"today" | "yesterday" | "week" | "month" | "year" | "all" | "custom">("today")
  const customDate = ref<[ dayjs.Dayjs, dayjs.Dayjs ] | null>(null)
  const mobileMenu = ref(false)

  const getDateParam = (_period?: typeof period.value): App.Params => {
    const date = dayjs()
    const date_to = date.format("YYYY-MM-DD")
    if (!_period) {
      _period = period.value
    }
    if (_period === "year") {
      return { date_from: date.startOf("year").format("YYYY-MM-DD"), date_to }
    }
    if (_period === "month") {
      return { date_from: date.startOf("month").format("YYYY-MM-DD"), date_to }
    }
    if (_period === "week") {
      return { date_from: date.startOf("week").format("YYYY-MM-DD"), date_to }
    }
    if (_period === "yesterday") {
      const date_to = date.subtract(1, "day").endOf("day").format("YYYY-MM-DD")
      return { date_from: date.subtract(1, "day").startOf("day").format("YYYY-MM-DD"), date_to }
    }
    if (_period === "today") {
      return { date_from: date.startOf("day").format("YYYY-MM-DD"), date_to }
    }
    if (_period === "custom") {
      return { date_from: customDate.value![0].format("YYYY-MM-DD"), date_to: customDate.value![1].format("YYYY-MM-DD") }
    }

    return { date_from: undefined, date_to: undefined }
  }

  const dataStore = useDataStore()
  const showBM = computed(() => {
    return dataStore.status === 'loaded' && dataStore.businesses.length === 0
  })

  const showFacebookAlert = ref(false)
  const reset = () => {
    showFacebookAlert.value = false
  }

  return {
    showBM,
    mobileMenu,
    period,
    customDate,
    showFacebookAlert,
    getDateParam,
    reset
  }

})