import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAccountStore } from "./stores/account";
import Home from './pages/Home.vue'
import Auth from './pages/Auth.vue'
import CommentsModeration from './pages/CommentsModeration.vue'
import AutoReplies from './pages/AutoReplies.vue'
import BlockList from './pages/BlockList.vue'
import WordStats from './pages/WordStats.vue'
import Logs from './pages/Logs.vue'
import SocialConnect from './pages/SocialConnect.vue'
import Billing from './pages/Billing.vue'
import ConfirmChangeEmail from './pages/ConfirmChangeEmail.vue'
import GettingStarted from './pages/GettingStarted.vue'
import Error404 from './pages/Error404.vue'
import { isSignUp } from "./utils/origin";

const routes: RouteRecordRaw[] = [
  { path: "/auth/:page?", component: Auth, name: "Auth" },
  { path: "/:pathMatch(.*)*", component: Error404 },
  { path: "/getting-started", component: GettingStarted, name: "GettingStarted" },
  { path: "/", component: Home, name: "Home", children: [
    { path: "/comments-moderation/:type*", component: CommentsModeration }, 
    { path: "/auto-replies/:type*", component: AutoReplies }, 
    { path: "/blocklists/:type*", component: BlockList }, 
    { path: "/word-stats/:type*", component: WordStats }, 
    { path: "/logs/:type*", component: Logs },
    { path: "/connect/facebook", component: SocialConnect, name: "FacebookConnect" },
    { path: "/connect/tiktok", component: SocialConnect, name: "TiktokConnect" },
  ]},
  { path: "/billing/:status", component: Billing },
  { path: "/confirm-change-email", component: ConfirmChangeEmail }
]

const signUpRoutes: RouteRecordRaw[] = [
  { path: "/auth/:page?", component: Auth, name: "Auth" },
  { path: "/:pathMatch(.*)*", component: Error404 },
  { path: "/", component: Auth, props: { signUpPage: true } },
]

export const router = createRouter({
  history: createWebHistory(),
  routes: isSignUp()? signUpRoutes: routes
})

router.beforeEach(async (to) => {
  const accountStore = useAccountStore()

  if (isSignUp()) return
  if (to.name === "Auth" && to.params.page !== "confirm") return

  if (accountStore.status === "not-authorized") {
    return '/auth'
  } else {
    if (accountStore.currentUser?.status_id === 0 && to.path !== "/auth/confirm") {
      return "/auth/confirm"
    }
    if (to.path === "/") {
      return "/comments-moderation/bms"
    }
  }
})