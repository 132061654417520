import { defineStore } from "pinia";
import { CSSProperties, Ref, ref, shallowRef } from "vue";

type MenuItem = {
  title: string,
  selected?: boolean,
  onClick: () => void
}

type Options = {
  placement?: "start" | "end",
  offsetY?: number
}

export const useContextMenuStore = defineStore('context-menu', () => {

  const opened = ref(false)
  const style = shallowRef<CSSProperties>({})
  const menuItems = shallowRef<MenuItem[]>()
  let anchorEl: HTMLElement | null = null

  const props = { placement: "start", offsetY: 8 }
  const openEl = (e: MouseEvent, _menuItems: MenuItem[], _props: Options = {}) => {
    opened.value = true
    anchorEl = e.currentTarget as HTMLElement
    menuItems.value = _menuItems
    props.placement = _props.placement || "start"
    props.offsetY = _props.offsetY ?? 8
  } 

  const windowSize = { width: window.innerWidth, height: window.innerHeight }
  const attachObject = (menuRef: Ref<HTMLElement | undefined>) => {
    if (!anchorEl || !opened.value) return
    const rect = anchorEl.getBoundingClientRect()
    let top = rect.bottom + props.offsetY
    let left = props.placement !== "end"? rect.left: undefined
    let right = props.placement === "end"? (windowSize.width - rect.right): undefined

    if (menuRef.value) {
      if (top + menuRef.value.clientHeight > windowSize.height-10) {
        top = windowSize.height- 10 - menuRef.value.clientHeight
      }

      if (typeof left === "number" && left + menuRef.value.clientWidth > windowSize.width-10) {
        left = windowSize.width - 10 - menuRef.value.clientWidth
      }
      if (typeof right === "number" && right + menuRef.value.clientWidth > windowSize.width-10) {
        right = windowSize.width - 10 - menuRef.value.clientWidth
      }
    }

    style.value = { top: top + "px", right: right && right + "px", left: left && left + "px", minWidth: rect.width+"px" }
  }

  return {
    opened,
    style,
    menuItems,
    openEl,
    attachObject
  }
})