import { computed } from "vue"

export const replace = <T extends object | Array<any>>(arr: T, to: T) => {
  if (Array.isArray(arr)) {
    arr.length = 0
    arr.push(...to as Array<any>)
  } else {
    for (let key of Object.keys(arr)) {
      delete arr[key as keyof T]
    }
    Object.assign(arr, to)
  }
}

export const copy = <T extends object>(to: T, obj: T, ...keys: string[]) => {
  for (let [ key, value ] of Object.entries(obj)) {
    if (!keys.includes(key)) continue
    to[key as keyof T] = value
  }
}

export const remove = <T extends object>(arr: T[], obj: T | ((obj: T) => boolean)) => {
  const index = typeof obj === "function"? arr.findIndex(obj): arr.indexOf(obj)
  if (index < 0) return
  arr.splice(index, 1)
}

export const useCheckboxVModel = <K extends string>(props: any, emit: (name: K, ...args: any[]) => void) => {
  const checked = computed({
    get() {
      if (Array.isArray(props.modelValue)) {
        return props.modelValue.includes(props.value)
      } else if (props.modelValue instanceof Set) {
        return props.modelValue.has(props.value)
      } else {
        return props.modelValue || false
      }
    },
    set(checked: boolean) {
      if (Array.isArray(props.modelValue)) {
        if (checked) {
          props.modelValue.push(props.value!)
        } else {
          props.modelValue.splice(props.modelValue.indexOf(props.value!), 1)
        }
        emit("update:modelValue" as K, props.modelValue)
      } else if (props.modelValue instanceof Set) {
        if (checked) {
          props.modelValue.add(props.value)
        } else {
          props.modelValue.delete(props.value)
        }
      } else {
        emit("update:modelValue" as K, checked)
      }
    }
  })
  
  return checked
}