import { facebookApi } from "@/api/facebook";
import { tiktokApi } from "@/api/tiktok";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";

type Business = {
  id: number,
  name: string,
  picture: string,
  type_id: number,
  pages: { id: number, name: string, picture?: string, type_id: number, active: boolean, bm: Business, instagrams: any[] }[],
  ad_accounts: { id: number, name: string, picture?: string, type_id: number, active: boolean, campaigns: any, bm: Business }[],
  active: boolean
}

type Account = {
  id: number,
  type_id: number,
  last_synced?: string,
  syncing?: boolean,
  name: string,
  avatar: string,
  active?: boolean,
  businesses: Business[]
}

export const useDataStore = defineStore('data', () => {

  const status = ref<"init" | "loaded" | "error">("init")
  const businesses = reactive<Business[]>([])
  const accounts = reactive<Account[]>([])

  const lockFacebookRequest = ref(false)
  const lockTiktokRequest = ref(false)

  const init = async () => {
    try {
      await Promise.all([ requestBusinesses(), requestTiktokBusinesses() ])
      status.value = "loaded"
    } catch (e) {
      status.value = "error"
      throw e
    }
  }

  const requestBusinesses = async () => {
    if (lockFacebookRequest.value) return
    const resp = await facebookApi.getAllInfo()

    for (let account of resp.elements) {
      account.type_id = 1
      account.picture = account.avatar
      accounts.push(account)
      for (let business of account.businesses) {
        businesses.push(business)
        business.type_id = 1
        const arr = []
        for (let page of business.pages) {
          page.type_id = 1
          page.bm = business
          arr.push(page)
          for (let instagram of page.instagrams) {
            instagram.type_id = 2
            instagram.bm = business
            instagram.name = instagram.username
            instagram.page = page
            arr.push(instagram)
          }
        }
        business.pages = arr
        for (let ad_account of business.ad_accounts) {
          ad_account.type_id = 1
          ad_account.bm = business
          for (let campaign of ad_account.campaigns) {
            campaign.type_id = 1
            campaign.bm = business
            campaign.ad_account = ad_account
          }
        }
      }
    }
  }

  const requestTiktokBusinesses = async () => {
    if (lockTiktokRequest.value) return
    const resp = await tiktokApi.getAllInfo()

    for (let element of resp.elements) {
      element.type_id = 3
      element.name = element.name || element.id.toString()
      element.ad_accounts = []
      element.pages = []
      element.campaigns = []
      for (let _item of element.advertisers) {
        for (let ad_account of _item) {
          ad_account.name = ad_account.name || ad_account.id
          ad_account.type_id = 3
          ad_account.bm = element
          element.ad_accounts.push(ad_account)
        }
      }
      businesses.push(element)
      accounts.push(element)
    }
  }

  const pages = computed(() => {
    const arr = []
    for (let business of businesses) {
      if (!business.pages) continue
      arr.push(...business.pages)
    }
    return arr
  })
  
  const ad_accounts = computed(() => {
    const arr = []
    for (let business of businesses) {
      if (business.ad_accounts) {
        arr.push(...business.ad_accounts)
      }
    }
    return arr
  })

  const campaigns = computed(() => {
    const arr = []
    for (let business of businesses) {
      if (!business.ad_accounts) continue
      for (let ad_account of business.ad_accounts) {
        if (!ad_account.campaigns) continue
        arr.push(...ad_account.campaigns)
      }
    }
    return arr
  })

  const instagrams = computed(() => {
    const arr = []
    for (let business of businesses) {
      if (!business.pages) continue
      for (let page of business.pages) {
        if (!page.instagrams) continue
        arr.push(...page.instagrams)
      }
    }
    return arr
  })

  const fillSet = (selected: App.Assets, item: { business_ids: number[], ad_account_ids: number[], page_ids: number[], campaign_ids: number[] }) => {
    selected.businesses = item.business_ids
    selected.pages = item.page_ids
    selected.ad_accounts = item.ad_account_ids
    selected.campaigns = item.campaign_ids
  }

  const reset = () => {
    status.value = "init"
    businesses.length = 0
    accounts.length = 0
  }

  return { 
    accounts,
    businesses,
    status,
    pages,
    ad_accounts,
    campaigns,
    instagrams,
    lockFacebookRequest,
    lockTiktokRequest,
    init,
    reset,
    fillSet,
    requestBusinesses,
    requestTiktokBusinesses
  }
})