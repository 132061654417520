type Options = Partial<{
  method: "GET" | "POST" | "DELETE" | "PUT"
}>

export class HttpError extends Error {
  code: number

  body?: any
  error?: any
  
  constructor(response: Response, body: any) {
    super()
    this.code = response.status
    if (body.error) {
      this.error = body.error
    } else {
      this.body = body
    }
  }
}

let jwt: string | null = null
export const request = async (url: string, body?: any, options: Options = {}) => {

  const headers: { [key: string]: string} = {}
  if (body) {
    headers["Content-Type"] = "application/json"
  }
  if (jwt) {
    headers["Authorization"] = "Bearer "+jwt
  }

  const _url = (!url.startsWith("http") && process.env.VUE_APP_URL)? (process.env.VUE_APP_URL + url): url

  const response = await fetch(_url, {
    method: options.method || (body? "POST": "GET"),
    body: body && JSON.stringify(body),
    headers,
    credentials: 'include'
  })

  const data = await response.json()

  if (response.status >= 400) {
    throw new HttpError(response, data)
  }

  return data
}

export const setJwt = (_jwt: string | null) => {
  jwt = _jwt
}

export const getJwt = () => {
  return jwt
}