import { tiktokApi } from "@/api/tiktok";
import { getOrigin } from "@/utils/origin";
import { defineStore } from "pinia";

export const useTiktokStore = defineStore('tiktok', () => {
  const connectTiktok = async (rewrite?: boolean) => {
    const redirect_uri = getOrigin() + "/connect/tiktok"
    const { url } = await tiktokApi.getConnectLink(redirect_uri, rewrite) 
    window.location.href = url
  }

  const confirmConnectTiktok = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = getOrigin() + "/connect/tiktok"
    const resp = await tiktokApi.connect({ code, state, redirect_uri })
    return resp
  }

  return { 
    connectTiktok,
    confirmConnectTiktok
  }
})