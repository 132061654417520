import { computed, reactive, ref } from "vue";
import { logsApi } from "@/api/logs";
import { replace } from "@/utils/vue";
import { defineStore } from "pinia";
import { useUiStore } from "./ui";
import { query } from "@/utils/api-utils";

type Log = any

export const useLogsStore = defineStore('logs', () => {

  const data = reactive<Record<string, any>>({})
  const uiStore = useUiStore()
  const loaded = ref(false)

  const params = reactive<App.Params>({ page: 1 })
  const meta = reactive({ totalCount: 0, pageCount: 1, currentPage: 1, perPage: 20 })

  const queryParams = computed(() => {
    const _params = uiStore.getDateParam()
    Object.assign(_params, params)

    return query(_params)
  })

  const logs = computed(() => {
    return data[queryParams.value]?.elements || []
  })

  const requestLogs = async () => {
    const _params = queryParams.value
    if (data[_params]) {
      replace(meta, data[_params]._meta)
      return
    }
    loaded.value = false
    const resp = await logsApi.getAll(_params)
    data[_params] = resp

    if (_params === queryParams.value) {
      replace(meta, resp._meta)
      loaded.value = true
      if (params.page && params.page > meta.pageCount) {
        params.page = meta.pageCount
      }
    }
  }

  const reset = () => {
    replace(data, {})
    loaded.value = false
  }

  const setPage = (page: number) => {
    meta.currentPage = page
    params.page = page
  }

  return {
    logs,
    loaded,
    meta,
    queryParams,
    params,
    setPage,
    requestLogs,
    reset
  }
})