import { query } from "@/utils/api-utils";
import { request } from ".";

type DrilldownParams = {
  word: string,
  left_word_count: number,
  right_word_count: number
}

export const drilldownApi = {
  get: (word: string) => request("/wordstats/view/"+word),
  create: (params: DrilldownParams) => request("/wordstats/create", params),
  update: (params: DrilldownParams) => request("/wordstats/update/"+params.word, params, { method: "PUT" }),
  preview: (ddParams: DrilldownParams, params: App.Params) => request("/wordstats/preview"+query(params), ddParams)
}
