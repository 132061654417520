import { DefineComponent, shallowRef } from "vue"
import { HoverOptions, useHover } from "./use-hover"

export type ContextMenuItem = { 
  title: string, 
  onClick?: (() => void) | false, 
  disabled?: boolean,
  class?: string,
  children?: ContextMenuItem[] | (() => ContextMenuItem[]) | false,
  icon?: DefineComponent<{}, {}, any>
}

export const useContextMenu = <T>(
  creator: ContextMenuItem[] | ((el: T) => (ContextMenuItem | null | undefined | false)[]), 
  options: HoverOptions = {}
) => {
  
  const menu = shallowRef<ContextMenuItem[] | null>(null)
  let arg: T | null = null
  const _onOpen = (e: MouseEvent, el?: T) => {
    arg = el || arg!
    const items = typeof creator === "function"? creator(arg): creator
    menu.value = items.filter(item => !!item) as ContextMenuItem[]
  }

  const $hover = useHover({ ...options, _onOpen })

  return { open, menu, ...$hover }
}