import { wordStatsApi } from "@/api/word-stats";
import { query } from "@/utils/api-utils";
import { replace } from "@/utils/vue";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { useUiStore } from "./ui";

export const useWordStatsStore = defineStore('word-stats', () => {

  const data = reactive<Record<string, any>>({})
  const uiStore = useUiStore()
  const loaded = ref(false)

  const params = reactive<App.Params>({ 
    page: 1, 
    search: "", 
    connect_ids: [],
    filter_no_block_list: false, 
    filter_no_replies_set: false, 
    filter_show_hidden: false 
  })

  const meta = reactive({ totalCount: 0, pageCount: 1, currentPage: 1, perPage: 20 })

  const queryParams = computed(() => {
    const _params = uiStore.getDateParam()
    
    Object.assign(_params, params)

    return query(_params)
  })

  const wordStats = computed(() => {
    return data[queryParams.value]?.elements || []
  })

  const requestAll = async () => {
    const _params = queryParams.value
    if (data[_params]) {
      replace(meta, data[_params]._meta)
      return
    }
    loaded.value = false
    const resp = await wordStatsApi.getAll(_params)
    data[_params] = resp

    if (_params === queryParams.value) {
      replace(meta, resp._meta)
      loaded.value = true
      if (params.page && params.page > meta.pageCount) {
        params.page = meta.pageCount
      }
    }
  }

  const setPage = (page: number) => {
    meta.currentPage = page
    params.page = page
  }

  const reset = () => {
    replace(data, {})
  }

  const hide = async (items: any[]) => {
    await wordStatsApi.hide(items.map(item => item.word))
    for (let item of items) {
      item.active = false
    }
  }
  
  const show = async (items: any[]) => {
    await wordStatsApi.show(items.map(item => item.word))
    for (let item of items) {
      item.active = true
    }
  }

  return {
    loaded,
    wordStats,
    meta,
    params,
    queryParams,
    requestAll,
    setPage,
    reset,
    hide,
    show
  }
})