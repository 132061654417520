import { defineStore } from "pinia";
import { nextTick, reactive } from "vue";

type ToastType = "success" | "info"

export const useToastStore = defineStore("toast", () => {

  const toasts = reactive<{ id: number, mode: "init" | "hide" | "show", text: string, type: ToastType }[]>([])

  const showToast = (text: string, type: ToastType = "info") => {
    toasts.unshift({ id: Math.trunc(Math.random()*100000), text, type, mode: "init" })
    const toast = toasts[0]
    nextTick(() => {
      setTimeout(() => {
        toast.mode = "show"
      }, 0)
    })
    setTimeout(() => {
      toast.mode = "hide"
      setTimeout(() => {
        toasts.pop()
      }, 500)
    }, 3000)
  }

  return { 
    toasts,
    showToast
  }

})