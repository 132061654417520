import { defineStore } from "pinia";
import { ref } from "vue";

export const useConfirmModal = defineStore('confirm-modal', () => {
  const opened = ref(false)
  const options = ref<{ anchorEl: HTMLElement, title: string, onConfirm: () => void | Promise<void> } | null>(null)

  let item: HTMLElement | null = null
  const close = () => {
    opened.value = false
    if (item) {
      item.classList.remove("hover")
    }
  }

  const open = (e: MouseEvent, title: string, onConfirm: () => void) => {
    opened.value = true
    item = e.currentTarget as HTMLElement
    item.classList.add("hover")
    options.value = {
      anchorEl: e.currentTarget as HTMLElement,
      title,
      onConfirm
    }
  }

  return {
    open,
    close,
    opened,
    options
  }
})