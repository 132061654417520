import { CSSProperties, reactive } from "vue"

export type HoverOptions = { 
  anchorOrigin?: string, 
  contentOrigin?: string, 
  contentSize?: number,
  _onOpen?: (e: MouseEvent, ...args: any) => void,
  _onClose?: () => void,
  offset?: {
    x?: number,
    y?: number
  },
  closeOnLeave?: boolean
}

export const useHover = ({ 
  anchorOrigin = "bottom left", 
  contentOrigin = "top left", 
  contentSize = 100, 
  _onOpen,
  _onClose,
  offset,
  closeOnLeave
}: HoverOptions = {}) => {
  
  const props = reactive({
    show: false,
    style: {},
    class: "",
    backdrop: false
  })

  const anchor = anchorOrigin.split(" ")
  const transform = contentOrigin.split(" ")
  props.class = "hover-panel " + "origin-"+transform.join("-")

  const calcStyle = (top: number, left: number) => {
    const pos: [ string, number ][] = [ 
      [ transform[0], top ],
      [ transform[1], left ]
    ]
    if (transform[0] === "bottom") {
      pos[0][1] = window.innerHeight - pos[0][1]
    }        
    if (transform[1] === "right") {
      pos[1][1] = window.innerWidth - pos[1][1]
    }
    if (offset) {
      pos[0][1] += (offset?.y || 0)
      pos[1][1] += (offset?.x || 0)
    }
    if (transform[1] === "center") {
      if (pos[1][1] > window.innerWidth/2) {
        pos[1] = [ "right", Math.max(window.innerWidth - pos[1][1] - contentSize/2, 0) ]
      } else {
        pos[1] = [ "left", Math.max(pos[1][1] - contentSize/2, 0) ]
      }
    }
    props.style = Object.fromEntries(pos.map(p => [ p[0], Math.round(p[1]/4)*4+"px" ]))
  }

  const recalcStyle = (el: HTMLDivElement) => {
    const pos: [ string, number ][] = [
      [ transform[0], parseInt((props.style as any)[transform[0]]) ],
      [ transform[1], parseInt((props.style as any)[transform[1]]) ]
    ]
    const offsetY = window.innerHeight - pos[0][1] - el.clientHeight - 8
    const offsetX = window.innerWidth - pos[1][1] - el.clientWidth - 8
    
    if (offsetX < 0 || offsetY < 0) {
      pos[0][1] += Math.min(0, offsetY)
      pos[1][1] += Math.min(0, offsetX)

      props.style = Object.fromEntries(pos.map(p => [ p[0], Math.round(p[1]/4)*4+"px" ]))
    }
  }

  const close = () => {
    props.show = false
    props.backdrop = false
    isHover = false
    if (_onClose) _onClose()
  }

  let isHover = false
  let timeout: number | null = null
  const hoverEmits = {
    mouseenter(e: MouseEvent, ...args: any) {
      const element = e.currentTarget as HTMLElement
      if (!element.classList.contains("hover-panel")) {
        const rect = element.getBoundingClientRect()
        calcStyle(rect[anchor[0] as "top" | "bottom"], rect[anchor[1] as "left" | "right"])
        isHover = true
      } else {
        if (!isHover) return
      }
      props.show = true
      if (_onOpen) _onOpen(e, ...args)
      if (timeout) {
        clearTimeout(timeout)
      }
    },
    mouseleave(e: MouseEvent) {
      if (!isHover || closeOnLeave === false) return
      timeout = setTimeout(() => {
        close()
      }, 100)
    }
  }

  const contextEmits = {
    contextmenu(e: MouseEvent, ...args: any) {
      props.backdrop = true
      calcStyle(Math.round(e.clientY/4)*4, Math.round(e.clientX/4)*4)
      props.show = true
      if (_onOpen) _onOpen(e, ...args)
    }
  }

  return { props, hoverEmits, contextEmits, close, recalcStyle }
}