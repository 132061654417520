import { defineStore } from "pinia";
import { ref } from "vue";

export const useTooltip = defineStore('tooltip', () => {

  let currentElement: HTMLElement | null = null
  const tooltip = ref<{ text: string, x: number, y: number, placement: string } | null>(null)

  const showTooltip = (e: MouseEvent, text: string, placement: "right" | "top" = "right") => {
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
    if (placement === "top") {
      tooltip.value = {
        text,
        x: rect.left + rect.width / 2,
        y: rect.top,
        placement
      }
    } else {
      tooltip.value = {
        text,
        x: rect.right,
        y: rect.top+rect.height/2,
        placement
      }
    }
    if (currentElement) {
      currentElement.removeEventListener("mouseleave", hideTooltip)
    }
    currentElement = e.currentTarget as HTMLElement
    currentElement.addEventListener("mouseleave", hideTooltip, { once: true })
  }

  const hideTooltip = () => {
    tooltip.value = null
  }

  return {
    tooltip,
    showTooltip
  }
})