import { reactive, ref } from "vue";
import { defineStore } from "pinia";
import { blockListsApi } from "@/api/block-lists";
import { remove, replace } from "@/utils/vue";
import { increment } from "@/utils/lang";
import { useCommentsStore } from "./comments";

type BlockList = {
  id: number,
  name: string,
  type_id: number,
  action_id: number,
  keys: string[],
  page_ids: number[],
  business_ids: number[],
  ad_account_ids: number[],
  "links": boolean,
  "images": boolean,
  "animated_images": boolean,
  "stickers": boolean,
  "phone_numbers": boolean,
  "emails": boolean
  existing: boolean,
}

export const useBlockListsStore = defineStore('block-lists', () => {

  const commentsStore = useCommentsStore()
  const blockLists = reactive<BlockList[]>([])
  const loaded = ref(false)

  let lastQueryTimestep = 0
  const requestAll = async () => {
    const resp = await blockListsApi.getAll()
    replace(blockLists, resp.elements)
    lastQueryTimestep = Date.now()
    loaded.value = true
  }

  const createBlockList = async (values: any) => {
    const resp = await blockListsApi.createBlockList(values)
    resp.block_words = 0
    blockLists.push(resp)

    commentsStore.applyBlockList([ ...values.business_ids, ...values.page_ids, ...values.ad_account_ids ], resp)
  }

  const editBlockList = async (itemId: number, values: any) => {
    const resp = await blockListsApi.editBlockList(itemId, values)
    const item = blockLists.find(item => item.id === itemId)
    if (item) {
      Object.assign(item, resp)
    }
  }

  const copy = async (item: any) => {
    const index = blockLists.indexOf(item)
    const copiedItem = { ...item }
    copiedItem.id = -Math.trunc(Math.random()*10000)
    copiedItem.name = increment(copiedItem.name)
    blockLists.splice(index+1, 0, copiedItem)
    const insertedItem = blockLists[index+1]
    {
      const data = await blockListsApi.get(item.id)
      delete data.id
      delete data.name
      Object.assign(item, data)
      Object.assign(insertedItem, data)
    }
    const resp = await blockListsApi.createBlockList(insertedItem)
    insertedItem.id = resp.id
  }

  const deleteBlockLists = async (ids: number[]) => {
    for (let id of ids) {
      remove(blockLists, item => item.id === id)
    }
    await blockListsApi.delete(ids)
  }

  const requestBlockListInfo = async (itemId: number) => {
    const resp = await blockListsApi.get(itemId)
    const item = blockLists.find(item => item.id === itemId)
    if (!item) return
    Object.assign(item, resp)
    return item
  }

  const reset = () => {
    blockLists.length = 0
    lastQueryTimestep = 0
    loaded.value = false
  }

  return {
    blockLists,
    loaded,
    reset,
    requestAll,
    requestBlockListInfo,
    createBlockList,
    editBlockList,
    copy,
    deleteBlockLists
  }

})