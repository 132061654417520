export const getOrigin = () => {
  return process.env.VUE_APP_ORIGIN || window.location.origin
}

export const isOrigin = () => {
  const origin = process.env.VUE_APP_ORIGIN
  if (!origin) return true
  return window.location.origin === origin 
}

export const getLoginLink = () => {
  return (process.env.VUE_APP_LOGIN || "") + '/auth'
}

export const hasLoginLink = () => {
  return !!process.env.VUE_APP_LOGIN
}

export const getSignUpLink = () => {
  return process.env.VUE_APP_SIGN_UP || '/auth/sign-up'
}

export const isSignUp = () => {
  return window.location.origin === process.env.VUE_APP_SIGN_UP
}

export const hasSignUpLink = () => {
  return !!process.env.VUE_APP_SIGN_UP 
}