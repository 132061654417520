import { repliesApi } from "@/api/replies";
import { increment } from "@/utils/lang";
import { remove, replace } from "@/utils/vue";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useUiStore } from "./ui";

type ReplySet = {
  id: number, 
  name: string, 
  ad_account_ids: number[], 
  business_ids: number[], 
  campaign_ids: number[], 
  page_ids: number[],
  replies_set_mode_id: number
}

export const useRepliesStore = defineStore('replies', () => {

  const replies = reactive<ReplySet[]>([])
  const uiStore = useUiStore()
  const loaded = ref(false)

  const requestReplies = async () => {
    const dateParams = uiStore.getDateParam()
    const resp = await repliesApi.getAll(dateParams)
    replace(replies, resp.elements)
    loaded.value = true
  }

  const create = async (values: any) => {
    const resp = await repliesApi.create(values)
    resp.comment_count = 0
    resp.comment_rate = 0
    resp.comment_replies_count = 0
    replies.push(resp)
  }
  
  const edit = async (id: number, values: any) => {
    const resp = await repliesApi.edit(id, values)
    const obj = replies.find(item => item.id === id)
    if (!obj) return
    Object.assign(obj, resp)
  }

  const copy = async (item: any) => {
    const index = replies.indexOf(item)
    const copiedItem = { ...item }
    copiedItem.id = -Math.trunc(Math.random()*10000)
    copiedItem.name = increment(copiedItem.name)
    replies.splice(index+1, 0, copiedItem)
    const insertedItem = replies[index+1]
    const resp = await repliesApi.create(copiedItem)
    insertedItem.id = resp.id
  }

  const deleteReplies = async (ids: number[]) => {
    await repliesApi.delete(ids)
    for (let id of ids) {
      remove(replies, item => item.id === id)
    }
  }

  const enable = async (items: any[]) => {
    await repliesApi.enable(items.map(item => item.id))
    for (let item of items) {
      item.active = true
    }
  }

  const disable = async (items: any[]) => {
    await repliesApi.disable(items.map(item => item.id))
    for (let item of items) {
      item.active = false
    }
  }

  const reset = () => {
    replies.length = 0
    loaded.value = false
  }

  return {
    replies,
    loaded,
    requestReplies,
    create,
    edit,
    copy,
    deleteReplies,
    disable,
    enable,
    reset,
  }
})