import { query } from "@/utils/api-utils";
import { request } from ".";

export const repliesApi = {
  getAll: (params: App.Params) => request("/replies/me"+query(params)),
  create: (values: any) => request("/replies/create", values),
  edit: (id: number, values: any) => request("/replies/update/"+id, values, { method: "PUT" }),
  delete: (ids: number[]) => request("/replies/delete", ids.map(id => ({ id })), { method: "DELETE" }),
  disable: (ids: number[]) => request("/replies/disable", ids.map(id => ({ id }))),
  enable: (ids: number[]) => request("/replies/enable", ids.map(id => ({ id })))
}