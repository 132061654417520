export const scrollToError = (parent: HTMLElement | null | undefined, errors: any) => {
  if (!parent) return
  for (let [ key, error ] of Object.entries(errors)) {
    if (!error) continue
    
    const el = parent.querySelector(`[name="${key}"]`)
    if (!el) continue

    const top = el.getBoundingClientRect().top - (parent.getBoundingClientRect().top - parent.scrollTop) - 100
    parent.scrollTo({ top, behavior: "smooth" })
    break
  }
}