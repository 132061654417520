export const getHeader = (header: string | string[]) => {
  if (Array.isArray(header)) {
    return header[0]
  }
  return header
}

export const getHeaderAsArray = (headers: string | string[]) => {
  if (Array.isArray(headers)) {
    return headers
  }
  return [headers]
}

export const query = (query: { [ key: string ]: string | number | boolean | null | undefined | string[] | number[] }, encodeURI = true) => {

  const keys = Object.keys(query).filter(key => !!query[key])
  if (keys.length === 0) return ""

  const params: string[] = []
  for (let key of keys) {
    const value = query[key]!
    if (Array.isArray(value)) {
      for (let val of value) {
        params.push(key + '[]=' + (encodeURI ? encodeURIComponent(val!.toString()) : val!.toString()))
      }
    } else {
      params.push(key + '=' + (encodeURI ? encodeURIComponent(value!.toString()) : value!.toString()))
    }
  }

  return "?"+params.join('&')
}

export const parseQuery = (url: string) => {
  const start = Math.max(url.indexOf("?")+1, 0)
  return Object.fromEntries(
    url
      .slice(start)
      .split("&")
      .map(item => item.split("="))
      .map(([ key, item ]) => [ key, item? decodeURIComponent(item): true ])
  )
}

export const parseCookie = (str: string): { [ key: string ]: string } => {
  return str
    .split(';')
    .map(v => v.split('='))
    .filter(v => v.length === 2)
    .reduce((acc, v) => {
      (acc as any)[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}
