import { request } from ".";

export const blockListsApi = {
  getAll: () => request("/blocks/me"),
  get: (blockId: number) => request("/blocks/view/"+blockId),
  createBlockList: (values: any) => request("/blocks/create", values),
  editBlockList: (id: number, values: any) => request("/blocks/update/"+id, values, { method: "PUT" }),
  delete: (ids: number[]) => request("/blocks/delete", ids.map(id => ({ id })), { method: "DELETE" }),

  connect: (blockListId: number, values: any) => request("/blocks/connect/"+blockListId, values),
  disconnect: (blockListId: number, values: any) => request("/blocks/disconnect/"+blockListId, values)
}