import { query } from "@/utils/api-utils";
import { request } from ".";
import { AuthParams } from "./account";

export const tiktokApi = {
  getAllInfo: () => request("/tiktok/me"),
  getConnectLink: (redirect_uri: string, rewrite?: boolean) => request("/tiktok/connect"+query({ redirect_uri, rewrite })),
  connect: ({ code, state, redirect_uri }: AuthParams) => request("/tiktok/connect"+query({ redirect_uri }), { code, state }),

  advertiser_enable: (body: { id: number }[]) => request("/tiktok/advertiser-enable", body),
  advertiser_disable: (body: { id: number }[]) => request("/tiktok/advertiser-disable", body),
  disconnect: (body: { id: number }[]) => request("/tiktok/disconnect", body)
}