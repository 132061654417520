<template>
  <div class="v-skeleton">

  </div>
</template>

<script lang="ts" setup>


  
</script>

<style lang="sass">
.v-skeleton
  background-color:  #CCCCCC
  min-width: 3em
  border-radius: 2px
  background-color: rgba(0, 0, 0, 0.1)
  animation: 1.5s ease-in-out 0.5s infinite normal none running v-skeleton-animation

  &:before
    content: "\00a0"
    white-space: nowrap

@keyframes v-skeleton-animation
  0%, 100%
    background-color: rgba(0, 0, 0, 0.1)

  50%
    background-color: rgba(0, 0, 0, 0.06)
    

</style>