<template>
  <div class="v-divider">
    <div class="v-divider__div"></div>
    <div class="v-divider__content">
      <slot></slot>
    </div>
    <div class="v-divider__div"></div>
  </div>
</template>

<style lang="sass">

.v-divider
  display: flex
  align-items: center

.v-divider__div
  border-bottom: 1px solid #E1E1E1
  flex: 1 1 auto

.v-divider__content
  padding: 0 20px
  color: #A0A0A0

</style>