export const num = (count: number, one: string, two: string) => {
  if (count === 1) return count + " " + one
  return count + " " + two
}

export const increment = (str: string) => {
  const match = str.match(/\(\d+\)$/)
  let val = str
  let count = 0
  if (match) {
    val = str.slice(0, -match[0].length)
    count = parseInt(match[0].slice(1, -1)) || 1
  }
  count ++
  return `${val.trimEnd()} (${count})`
}

export const incrementSimple = (str: string) => {
  const match = str.match(/\d+$/)
  let val = str
  let count = 0
  if (match) {
    val = str.slice(0, -match[0].length)
    count = parseInt(match[0].slice(1, -1)) || 1
  }
  count ++
  return `${val.trimEnd()} ${count}`
}