import { reactive, watch } from "vue"

export const useErrors = (values: any) => {

  const errors = reactive<any>({})

  const oldValues: { [key: string]: string } = {}
  watch(values, () => {
    for (let [ key, value ] of Object.entries(values)) {
      if (errors[key] && (value !== oldValues[key] || (Array.isArray(value)))) {
        delete errors[key]
      }
    }
    Object.assign(oldValues, values)
  }, { deep: true })

  const checkRequired = (required: string[]) => {
    let success = true
    for (let key of required) {
      if (!values[key]) {
        errors[key] = `${key[0].toUpperCase()+key.slice(1).replace(/_/g, " ")} can't be empty`
        success = false
      }
    }
    return success
  }

  return {
    errors,
    checkRequired
  }
}