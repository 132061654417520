import { onBeforeMount, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"

export const useTabs = (tabs: Array<{ id: string, name: string, path: string }>) => {

  const router = useRouter()

  const types = router.currentRoute.value.params.type
  const type = types && Array.isArray(types)? (types.length > 0 && types[0]): types
  const route = type && tabs.find(item => item.path === type)?.id
  const currentTab = ref(route || tabs[0].id)

  watch(currentTab, (value) => {
    const path = router.currentRoute.value.matched[router.currentRoute.value.matched.length-1].path
    const route = tabs.find(item => item.id === value)
    if (!route) return
    router.push(path.replace(/\/\:type.?/, route.path? `/${route.path}`: ""))
  })

  return { currentTab, tabs }
}