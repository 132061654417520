import BillingInfoDialogVue from "@/components/dialogs/BillingInfoDialog.vue"
import CreateAutoReplyDialogVue from "@/components/dialogs/CreateAutoReplyDialog.vue"
import CreateBlockListDialogVue from "@/components/dialogs/CreateBlockListDialog.vue"
import SettingsDialogVue from "@/components/dialogs/SettingsDialog.vue"
import { useDialogStore } from "@/stores/dialogs"
import { onMounted, watch } from "vue"
import { useRouter } from "vue-router"

const dialogs = {
  "settings": SettingsDialogVue,
  "billing": BillingInfoDialogVue,
  "new-replies-set": CreateAutoReplyDialogVue,
  "edit-replies-set": CreateAutoReplyDialogVue,
  "new-blocklist": CreateBlockListDialogVue,
  "edit-blocklist": CreateBlockListDialogVue
}

export const useDialogRouter = () => {

  const router = useRouter()
  const dialogStore = useDialogStore()
  
  onMounted(() => {
    const type = router.currentRoute.value.params.type
    if (!type || type.length === 0) return
    for (let [ key, dialog ] of Object.entries<any>(dialogs)) {
      if (type[0] === key) {
        dialogStore.open(dialog)
        return
      }
    }
  })
  
  let lastPath = ""
  watch(() => dialogStore.dialog, (value, lastValue) => {
    const path = router.currentRoute.value.matched[router.currentRoute.value.matched.length-1].path
    if (value.dialog === null && Object.values(dialogs).find(dialog => dialog === lastValue.dialog)) {
      if (lastPath) {
        router.push(lastPath)
        lastPath = ""
      } else {
        router.push(path.replace(/\/\:type.?/, ""))
      }
      return
    }

    const types = router.currentRoute.value.params.type
    if (types[0] in dialogs) {
      return
    }

    for (let [ key, dialog ] of Object.entries(dialogs)) {
      if (dialog === value.dialog) {
        lastPath = router.currentRoute.value.path
        if (value.props?.item) {
          if (key.startsWith("new")) continue
          router.push(path.replace(/\:type.?/, `${key}/${value.props.item.id}`))
          return
        }
        router.push(path.replace(/\:type.?/, key))
        return
      }
    }
  })

}