import { facebookApi } from "@/api/facebook";
import { getOrigin } from "@/utils/origin";
import { defineStore } from "pinia";

export const useFacebookStore = defineStore("facebook", () => {

  const connectFacebook = async (rewrite?: boolean) => {
    const redirect_uri = getOrigin() + "/connect/facebook"
    const { url } = await facebookApi.getConnectLink(redirect_uri, rewrite) 
    window.location.href = url
  }

  const confirmConnectFacebook = async ({ code, state }: { code: string, state: string }) => {
    const redirect_uri = getOrigin() + "/connect/facebook"
    const resp = await facebookApi.connectToFacebook({ code, state, redirect_uri })
    return resp
  }

  return { 
    connectFacebook,
    confirmConnectFacebook
  }

})