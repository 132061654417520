import { ref, reactive } from "vue";
import { replace } from '@/utils/vue'
import { billingApi } from "@/api/biling";
import { defineStore } from "pinia";

type PaymentDetails = {
  free_actions: number,
  max_free_actions: number,
  paid_actions: number,
  pending_balance: number
}

type CardInfo = {
  "full_name": string,
  "country": string,
  "line1": string,
  "line2": string,
  "postal_code": string,
  "city": string,
  "state": string,
  "card": {
      "brand": string
      "exp_month": string,
      "exp_year": string,
      "last4": string
  },
  "next_payment": {
      "date": string,
      "total": 0
  }
}

export const useBillingStore = defineStore('biling', () => {

  const details = ref<PaymentDetails>()
  const cardInfo = ref<CardInfo | null>()
  const history = reactive<{ currency: string, date: string, invoice_number: string, pdf_url: string, total: number }[]>([])

  const historyLoaded = ref(false)

  const requestAll = async () => {
    await requestDetails()

    const resp3 = await billingApi.getHistory()
    replace(history, resp3.elements)
    historyLoaded.value = true

    try {
      const resp2 = await billingApi.me()
      cardInfo.value = resp2 
    } catch(e) {
      cardInfo.value = null
    }
  }

  const requestDetails = async () => {
    details.value = undefined
    const resp = await billingApi.getDetails()
    details.value = resp
  }

  const payNow = async () => {
    const resp = await billingApi.payNow()
    console.log(resp)
  }

  const addCard = async () => {
    const resp = await billingApi.addCard()
    window.location.href = resp.url
  }

  const cancelSubscription = async () => {
    const resp = await billingApi.cancel()
    cardInfo.value = null
  }

  const retrieveAfterAddCard = async (sessionId: string) => {
    const resp = await billingApi.retrieve(sessionId)
    cardInfo.value = resp
    return resp
  }

  const reset = () => {
    details.value = undefined
    cardInfo.value = undefined
    history.length = 0
    historyLoaded.value = false
  }

  return {
    details,
    cardInfo,
    history,
    historyLoaded,
    requestAll,
    requestDetails,
    payNow,
    addCard,
    retrieveAfterAddCard,
    reset,
    cancelSubscription
  }
})