import { query } from "@/utils/api-utils";
import { request } from ".";

export type AuthParams = { code: string, state: string, redirect_uri?: string }

export const accountApi = {
  getCurrentUserInfo: () => request("/users/me"),

  register: ({ email, password }: { email: string, password: string }) => request("/users/register", { email, password }),
  login: ({ login, password }: { login: string, password: string }) => request("/users/login", { login, password }),
  forgotPassword: ({ email }: { email: string }) => request("/users/forgot", { email }),
  recoverPassword: ({ password, password_repeat, token }: any) => request("/users/recover"+query({ token }), { password, password_repeat }),

  getGoogleLink: (redirect_uri: string) => request("/users/social/google"+query({ redirect_uri })),
  authByGoogle: ({ code, state, redirect_uri }: AuthParams) => request("/users/social/google"+query({ redirect_uri }), { code, state }),
  connectGoogleAuth: ({ code, state, redirect_uri }: AuthParams) => request("/users/connect/google"+query({ redirect_uri }), { code, state }),
  disconnectGoogleAuth: () => request("/users/disconnect/google", {}),
  
  getFacebookLink: (redirect_uri: string) => request("/users/social/facebook"+query({ redirect_uri })),
  authByFacebook: ({ code, state, redirect_uri }: AuthParams) => request("/users/social/facebook"+query({ redirect_uri }), { code, state }),
  connectFacebookAuth: ({ code, state, redirect_uri }: AuthParams) => request("/users/connect/facebook"+query({ redirect_uri }), { code, state }),
  disconnectFacebookAuth: () => request("/users/disconnect/facebook", {}),

  requestCode: () => request("/users/send-code"),
  activateCode: ({ code }: { code: string }) => request("/users/activate", { code }),

  changePassword: ({ password, new_password, new_password_repeat }: any) => request("/users/change-password", { password, new_password, new_password_repeat }),
  changeEmail: ({ password, email }: any) => request("/users/change-email", { email, password }),
  confirmChangeEmail: (token: string) => request("/users/confirm-change-email" + query({ token }), {}),

  validateReferal: (code: string) => request("/users/validate-referal", { code }),
  applyReferal: (code: string) => request("/users/referal", { code }),
}